<!-- <input class="form-control m-r-20 rounded-5" placeholder="Search"> -->
<a *ngIf="authService.hasPermission('add-pet')" [routerLink]="['/clinic/add-pet']"
    class=" m-r-20 hidden-sm-up rounded-5 text-white  m-w-p-150" mat-raised-button color="primary">
  Add Pet
  <mat-icon>add_circle</mat-icon>
</a>

<div class="m-l-16 text-dark d-flex align-items-center pointer"
     [matMenuTriggerFor]="profile">
  <div>
    <p class="f-w-400 m-0 mat-subtitle-1">
      <ng-container *ngIf="authService.getUserRole() !== 'CLINIC'">
        <span *ngIf="authService.getUserName()">{{authService.getUserName()}}</span>
      </ng-container>
      <ng-container *ngIf="authService.getUserRole() === 'CLINIC'">
        <span *ngIf="authService.getUserName()">{{authService.getUserName()}}</span>
        <span *ngIf="!authService.getUserName()">{{authService.getUserEmail()}}</span>
      </ng-container>
    </p>
    <p class="m-0 fs-14" style="line-height: 1;">
      <span *ngIf="authService.getClinicName()" class="text-secondary">{{authService.getClinicName()}}</span>
    </p>
  </div>
  <mat-icon >expand_more</mat-icon>
</div>

<mat-menu #profile="matMenu" class="mymegamenu">
  <a *ngIf="currentUser?.role !== 'SUPER_ADMIN'" mat-menu-item [routerLink]="[profileLink]" [queryParams]="{id: authService.getUserId()}">
    <mat-icon>account_box</mat-icon> Profile
  </a>
  <a mat-menu-item (click)="onLogout()">
    <mat-icon>exit_to_app</mat-icon> Sign Out
  </a>
</mat-menu>






<!-- <a [routerLink]="profileLink" class=" m-r-20 hidden-sm-up"
  mat-raised-button color="warn">Dr. Olivia</a>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 p-0 d-flex jc-c align-items-center">
  <img src="assets/images/users/1.jpg" alt="user" class="profile-pic">
</button>

<div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
    <img src="assets/images/users/1.jpg" class="profile-pic">
    <div class="m-l-16">
        <h6 class="f-w-600 m-0 mat-subtitle-1">Mathew Anderson</h6><span
            class="d-block m-b-4">Designer</span>
    </div>
</div>

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings </button>
    <a mat-menu-item [routerLink]="profileLink">
        <mat-icon>account_box</mat-icon> Profile </a>
    <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications </button>
    <a mat-menu-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </a>
</mat-menu> -->
