import { Injectable } from "@angular/core";
import { configuration } from "../configuration";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { EncryptionService } from "./encryptions.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from "ngx-toastr";
const AUTH = configuration.AUTH_KEY;
const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public data: AuthData = new AuthData();
  reqData: any = [];
  institutionInfo: any = [];
  encrypt: any;
  private userLoggedIn = new Subject<boolean>();
  public authDetails: any;

  constructor(
    public router: Router,
    public encryptionService: EncryptionService,
    private toastr: ToastrService
  ) {
    if (localStorage.getItem(AUTH) != null) {

    } else {
      localStorage.clear();
    }
  }

  getToken() {
    if (localStorage.getItem(AUTH) != null) {
      let decrypt = this.encryptionService.decrypt(
        localStorage.getItem(AUTH) || "{}"
      );
      this.data = JSON.parse(decrypt);
    }
    return this.data.token;
  }

  getVerifyToken() {
    let verifyToken;
    if (localStorage.getItem("verifyToken") != null) {
      verifyToken = localStorage.getItem("verifyToken");
    }
    return verifyToken;
  }

  getAuthDetail() {
    if (localStorage.getItem(AUTH) != null) {
      let decrypt = this.encryptionService.decrypt(
        localStorage.getItem(AUTH) || "{}"
      );

      this.data = JSON.parse(decrypt);
    }
    this.authDetails = helper.decodeToken(this.data.token);
    return this.data;
  }

  setAuth(data: AuthData) {
    this.data = data;

    localStorage.removeItem(AUTH);

    this.encrypt = this.encryptionService.encrypt(JSON.stringify(data));
    localStorage.setItem(AUTH, this.encrypt);
    let decrypt = this.encryptionService.decrypt(
      localStorage.getItem(AUTH) || "{}"
    );

    this.data = JSON.parse(decrypt);
    this.getAuthDetail();
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/auth']);  // Redirect to the login page after logout
    this.userLoggedIn.next(false);  // Notify other parts of the app that the user is logged out
  }

  sessiontimeout() {
    localStorage.removeItem(AUTH);
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem(AUTH) != null) {
      let decrypt = this.encryptionService.decrypt(
        localStorage.getItem(AUTH) || "{}"
      );
      this.data = JSON.parse(decrypt);

      if (helper.isTokenExpired(this.data.token)) {
        this.logout();
        this.toastr.error('Session expired, Please re-login.')
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getUserRole() {
    return this.authDetails.role;
  }

  getUserId() {
    return this.data.authDetail.id;
  }

  getUserName() {
    if(this.data.authDetail.role === "VET"){
      return this.data.authDetail.first_name
    }else return this.data.authDetail.name;
  }

  getUserEmail() {
    return this.data.authDetail.email;
  }

  getClinicName() {
    return this.data.authDetail.clinic_name;
  }

  hasPermission(permission: string) {
    if(this.getUserRole() == "SUPER_ADMIN") {
      return true;
    }
    let permissionIndex = configuration.ROLE_PERMISSIONS[this.getUserRole()].indexOf(permission);
    if(permissionIndex > -1) {
      return true;
    }
    return false;
  }

}



export class AuthData {
  isLoggedIn: boolean = false;
  token!: string;
  verifyToken: string | undefined;
  authDetail: any;
}
