import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="preloader" *ngIf="isSpinnerVisible">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = false;
  private subscription: Subscription;

  constructor(private spinnerService: SpinnerService) {
    this.subscription = this.spinnerService.spinnerState.subscribe(visible => {
      this.isSpinnerVisible = visible;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
