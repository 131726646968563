export class Configuration {
  BASE_URL = "";
  BASE_URL_AUTH = "";
  BASE_URL_AI = "";
  AUTH_KEY: string = "authDetail";
  PAGINATION_LIMIT = 10;
  TINY_KEY = "ukwtibwj37mou9bqrxkwqzcczx1x584oe1752pni3vj3soga";
  TINY_CONFIG = {
    plugins: [
      // Core editing features
      'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
      // Your account includes a free trial of TinyMCE premium features
      // Try the most popular premium features until Oct 5, 2024:
      // 'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
    ],
    menubar: 'edit insert format tools',
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    tinycomments_mode: 'embedded',
    tinycomments_author: 'PetchTech',
    branding: false
  }

  constructor(public state: projectState) {
    if (this.state == "staging") {
      this.BASE_URL_AUTH = "https://dev.environment.com/";
      this.BASE_URL_AI = "https://dev.ai.environment.com/";
    } else if (this.state == "qa") {
      this.BASE_URL_AUTH = "https://qa.environment.com";
      this.BASE_URL_AI = "https://qa.ai.environment.com";
    } else if (this.state == "production") {
      this.BASE_URL_AUTH = "https://api.petchtech.com/";
      this.BASE_URL_AI = "https://ai.petchtech.com/";
    } else if (this.state == "preprod") {
      this.BASE_URL_AUTH = "https://preprod.environment.com";
      this.BASE_URL_AI = "https://preprod.ai.environment.com";
    } else if (this.state == "local") {
      this.BASE_URL_AUTH = "http://localhost:8001/";
      this.BASE_URL_AI = "http://localhost:8000/";
    }
  }

  ROLE_PERMISSIONS: any = {
    CLINIC: ["add-pet", "add-vet"],
    VET: ["add-pet", "add-vet"]
  }
}
type projectState = "staging" | "production" | "qa" | "preprod" | "local";
export const configuration = new Configuration("production");
