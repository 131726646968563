import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent {
  profileLink: string = '';
  userId: string | null | undefined;
  currentUser: any;

  constructor(public authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getAuthDetail().authDetail;
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if(this.authService.getUserRole() == "CLINIC") {
      this.profileLink = 'clinic/profile';
    } else if(this.authService.getUserRole() == "VET" || this.authService.getUserRole() === "SUPER_ADMIN") {
      this.profileLink = 'clinic/profile';
    }
  }
  onLogout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  getInitials(name: string): string {
    const nameSplit = name.split(' ');
    const initials = nameSplit.map(n => n[0]).join('');
    return initials.substring(0, 2).toUpperCase(); // Take only first 2 characters
  }

}
